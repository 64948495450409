<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      dataY: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      dataX: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'dataY':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
      'dataX':{
        handler() {
           this.refreshData()
        },
        deep:true,
      }
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
        this.chart.setOption({
            grid: {
                top: 10,
                bottom: 5,
                right: 15,
                left: 0,
                containLabel: true,
            },
            legend:{
                textStyle:{
                    color:'#fff'
                }
            },
            xAxis: {
                type: 'value',
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                min:0,
                max:100,
                axisLabel: {
                    show: true,
                    textStyle:{
                        color:'#fff'
                    },
                    formatter: '{value}%'
                },
            },
            yAxis: [
                {
                    triggerEvent: false,
                    show: true,
                    data: ['上海博物馆', '上海历史博物馆', '上海市文物局集中库房'],
                    axisLine : {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        textStyle:{
                            color:'#fff'
                        }
                    },
                },
            ],
            series: [
                {
                    name: '近24h',
                    type: 'bar',
                    showBackground: true,
                    data: [59,60,72],
                    barWidth: 10,
                    barBorderRadius: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            {
                                offset: 0,
                                color: 'rgb(0,251,253,0)',
                            },
                            {
                                offset: 1,
                                color: 'rgb(0,251,253,1)',
                            },
                        ]),
                        barBorderRadius: 10,
                    },
                },
                {
                    name: '同比',
                    type: 'bar',
                    showBackground: true,
                    barBorderRadius: 10,
                    data: [50,55,45],
                    barWidth: 10,
                    barGap:'300%',
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            {
                                offset: 0,
                                color: 'rgb(246, 224, 61,0)',
                            },
                            {
                                offset: 1,
                                color: 'rgb(246, 224, 61,1)',
                            },
                        ]),
                        barBorderRadius: 10,
                    },
                },
            ],
          })    
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>